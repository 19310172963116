import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
import { serialize } from "object-to-formdata";
import { addConversation, getAllConversations } from "./conversation";
import { addMessageFromOperator } from "./messages";
import { emitEvent } from "../socket/socket";

const initialState = {
  newMessage: "",
  isshortcut: false,
  file: null,
  open: false,
  isShortcutFilterCard: false,
};

export const dropFirstMessageFromOperator = createAsyncThunk(
  "newMessage/dropFirstMessageFromOperator",
  async (data, thunkAPI) => {
    try {
      let file;
      let response;
      if (data?.file) {
        data?.content.trim() !== ""
          ? (file = serialize({
              file: data?.file,
              content: data?.content,
            }))
          : (file = serialize({ file: data?.file }));
        response = await axios.post(
          `/websites/${data?.websiteID}/messages/direct-message/${data?.clientID}`,
          file
        );
      } else {
        response = await axios.post(
          `/websites/${data?.websiteID}/messages/direct-message/${data?.clientID}`,
          {
            content: data?.content,
          }
        );
      }
      if (response.status === 200) {
        const conversation = response.data.data.conversation;
        const messageID = response.data.data._id;
        emitEvent("DM_USER", {
          webSiteId: data.websiteID,
          content: data?.content,
          createdBy: data?.user,
          from: "OPERATOR",
          file: data?.file ? URL.createObjectURL(data?.file) : null,
          filetype: data?.file ? data?.file?.type : null,
          _id: messageID,
          conversation: conversation,
          isNote: false,
          id: data?.clientID,
          createdAt: new Date(Date.now()),
        });
        data?.conversationsStored?.length === 0
          ? thunkAPI.dispatch(
              getAllConversations({ websiteID: data?.websiteID, page: 1 })
            )
          : thunkAPI.dispatch(
              addConversation({
                ...conversation,
                assigned: {
                  ...conversation?.assigned,
                  user: data?.user,
                },
                lastMessage: {
                  content: data?.content,
                  createdBy: data?.user,
                  from: "OPERATOR",
                  file: data?.file ? URL.createObjectURL(data?.file) : null,
                  filetype: data?.file ? data?.file?.type : null,
                  _id: messageID,
                  conversation: conversation?._id,
                  createdAt: new Date(Date.now()),
                },
              })
            );
        thunkAPI.dispatch(
          addMessageFromOperator({
            content: data?.content,
            createdBy: data?.user,
            from: "OPERATOR",
            file: data?.file ? URL.createObjectURL(data?.file) : null,
            filetype: data?.file ? data?.file?.type : null,
            _id: messageID,
            conversation: conversation?._id,
            isNote: false,
          })
        );
        return response;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message);
    }
  }
);

const slice = createSlice({
  name: "newMessage",
  initialState,
  reducers: {
    changeNewMessageSuccess: (state, action) => {
      state.newMessage = action.payload;
    },
    isShortcut: (state, action) => {
      state.isshortcut = action.payload;
    },
    changeFileSuccess: (state, action) => {
      state.file = action.payload ? action.payload : null;
    },
    openImageSuccess: (state) => {
      state.open = true;
    },
    closeImageSuccess: (state) => {
      state.open = false;
    },
    isShortcutFilterCard: (state, action) => {
      action.payload === "Shortcuts"
        ? (state.isShortcutFilterCard = true)
        : (state.isShortcutFilterCard = false);
    },
  },
  extraReducers: {},
});

export const reducer = slice.reducer;
export const {
  changeNewMessageSuccess,
  changeFileSuccess,
  openImageSuccess,
  closeImageSuccess,
  isShortcut,
  isShortcutFilterCard,
} = slice.actions;
export default slice;

export const changeNewMessage = (data) => async (dispatch) => {
  try {
    dispatch(changeNewMessageSuccess(data));
  } catch (error) {
    return console.error(error.message);
  }
};

export const changeFile = (data) => async (dispatch) => {
  try {
    dispatch(changeFileSuccess(data));
  } catch (error) {}
};

export const openImage = () => async (dispatch) => {
  try {
    dispatch(openImageSuccess());
  } catch (error) {
    return console.error(error.message);
  }
};

export const closeImage = () => async (dispatch) => {
  try {
    dispatch(closeImageSuccess());
  } catch (error) {
    return console.error(error.message);
  }
};
